import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePageRealestatedataCs } from '../hooks/cs/page-realestate.metadata';
import { usePageRealestatedataEn } from '../hooks/en/page-realestate.metadata.en';

export function PageRealestateMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePageRealestatedataCs();
  } else {
    data = usePageRealestatedataEn();
  }
  return data;
}
